<template>
  <div class="user-index ly-wrap">
    <div class="header-box">
      <h1>欢迎您，{{ user.realname }}！</h1>
      <p>{{ selectWater.name }}</p>
      <p>{{ user.orgCode }}</p>
      <div class="user-image"></div>
    </div>
    <div class="ly-center">
      <div class="info-box up">
        <div class="io-item" v-track-event="'操作记录, 进入'" @click="goWay('/user/record')" style="border-top: none;">
          <div class="icon i-czjl"></div>
          <div class="txt">
            <div class="t-title">操作记录</div>
            <div class="t-sub">记录你在本平台进行的相关操作</div>
          </div>
          <div class="more"></div>
        </div>
        <div class="io-item" v-track-event="'系统设置, 进入'" @click="goWay('/user/set')">
          <div class="icon i-xtsz"></div>
          <div class="txt">
            <div class="t-title">系统设置</div>
            <div class="t-sub">设置各变量报警消息推送接收人</div>
          </div>
          <div class="more"></div>
        </div>
        <div class="io-item" v-track-event="'关于我们, 进入'" @click="goWay('/user/about')">
          <div class="icon i-gywm"></div>
          <div class="txt">
            <div class="t-title">关于我们</div>
            <div class="t-sub">如有任何疑问请联系我们</div>
          </div>
          <div class="more"></div>
        </div>
      </div>
    </div>

    <div class="ly-center">
      <div class="info-box down">
        <div class="io-item" v-track-event="'个人信息, 进入'" @click="goWay('/user/info')" style="border-top: none;">
          <div class="icon i-grxx"></div>
          <div class="txt">
            <div class="t-title">个人信息</div>
            <div class="t-sub">查看和修改您的资料和密码</div>
          </div>
          <div class="more"></div>
        </div>
        <div class="io-item" v-track-event="'通讯录, 进入'" @click="goWay('/user/mail-list')">
          <div class="icon i-txl"></div>
          <div class="txt">
            <div class="t-title">通讯录</div>
            <div class="t-sub">工作常用联系人资料</div>
          </div>
          <div class="more"></div>
        </div>
      </div>
    </div>

    <div class="ly-center" style="padding-bottom: 1.8rem">
      <div class="out-btn" @click="handleOut" v-track-event="'退出登录, 退出'">退出登录</div>
    </div>

    <ly-foot-tab footOn="user"></ly-foot-tab>
  </div>
</template>
<script>

import {getUserInfoByToken, logout} from '../../api/api.js'
import {getSelectWater, getUserInfo, removeAccessToken,} from '../../utils/auth'
import {Toast} from 'mint-ui';
import LyFootTab from "@/components/LyFootTab";

export default {
  name: 'UserIndex',
  components: {LyFootTab},
  computed: {},
  data() {
    return {
      user: {},
      userInfo: {},
      selectWater: {},
    }
  },
  mounted() {

  },
  created() {
    this.loadBase()
  },
  methods: {
    async loadBase() {
      this.userInfo = await getUserInfo()
      this.selectWater = await getSelectWater()
      getUserInfoByToken().then(res => {
        this.user = res.data.result
      })
    },
    handleOut() {
      logout().then(res => {
        if (res.data.success) {
          removeAccessToken()
          Toast({
            message: '退出成功，请重新登录',
            position: 'top',
            duration: 2000
          })
          setTimeout(() => {
            this.$router.push({path: '/login'})
          }, 500)
        } else {
          Toast({
            message: res.data.message || '退出异常',
            position: 'top',
            duration: 2000
          })
        }
      })
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">
.user-index {
  width: 100%;
  min-height: 100%;
  background-color: #F2F2F2FF;

  .header-box {
    width: 100%;
    height: 4.4rem;
    overflow: hidden;
    background: #0088FF;
    box-shadow: 0px 7px 17px 0px rgba(0, 136, 255, 0.2);
    position: relative;

    h1 {
      font-size: 0.48rem;
      font-weight: bold;
      color: #FFFFFF;
      padding-left: 0.51rem;
      padding-top: 0.8rem;
    }

    p {
      font-size: 0.3rem;
      font-weight: 400;
      color: #FFFFFF;
      padding-left: 0.51rem;
    }

    .user-image {
      position: absolute;
      width: 2.96rem;
      height: 3.98rem;
      background: url("~@/assets/img/user/u-image.png") no-repeat center;
      background-size: 2.96rem;
      right: 0.24rem;
      top: 0.35rem;
    }
  }

  .info-box {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0 0.07rem 0.17rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.1rem;
    position: relative;
    top: -0.8rem;

    .io-item {
      width: 100%;
      height: 1.75rem;
      border-top: 1px solid #E2E2E2FF;
      display: flex;

      .icon {
        width: 1.3rem;
        height: 1.75rem;
        background-repeat: no-repeat;
        background-size: 1.3rem;
        background-position: center;
        margin-left: 0.2rem;
      }

      .i-czjl {
        background-image: url("~@/assets/img/user/i-czjl.png");
      }

      .i-xtsz {
        background-image: url("~@/assets/img/user/i-xtsz.png");
      }

      .i-gywm {
        background-image: url("~@/assets/img/user/i-gywm.png");
      }

      .i-grxx {
        background-image: url("~@/assets/img/user/i-grxx.png");
      }

      .i-txl {
        background-image: url("~@/assets/img/user/i-txl.png");
      }

      .txt {
        flex: 1;
        padding-left: 0.2rem;

        .t-title {
          font-size: 0.36rem;
          font-weight: bold;
          color: #333333;
          line-height: 0.8rem;
          padding-top: 0.2rem;
        }

        .t-sub {
          font-size: 0.28rem;
          font-weight: 400;
          color: #999999;
        }
      }

      .more {
        width: 0.6rem;
        background: url("~@/assets/img/more-grey.png") no-repeat left center;
        background-size: 0.2rem;
      }
    }
  }

  .down {
    top: -0.5rem;
  }

  .out-btn {
    width: 100%;
    height: 0.88rem;
    line-height: 0.88rem;
    background: #0088FF;
    box-shadow: 0 0.1rem 0.13rem 0 rgba(0, 136, 255, 0.34);
    border-radius: 0.1rem;
    font-size: 0.36rem;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
  }
}
</style>
